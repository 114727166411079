import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cal330 from './pages/Calculate_330F';
import Harm from './pages/harm';
import Loading330 from './pages/Loading330';
import KilitMaindeck from './pages/KilitMaindeck';
import AllFormsa from './pages/AllFormsa';
import Main from './pages/Main';
import LenghtCal from './pages/LenghtCal';
import UnChecker from './pages/UnChecker';
import CaoAccess from './pages/CaoAccess';

import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import checkIsPwa from 'check-is-pwa';
import PWAInstallComponent from "./components/pwa-install";
import { AppProvider } from './components/AppContext'; // AppContext'i import et



const App = () => {
  const [isPWA, setIsPWA] = useState(checkIsPwa());
  

  useEffect(() => {
    const updatePWAStatus = () => {
      setIsPWA(checkIsPwa());
    };

    // Sayfa yüklendiğinde ve odaklandığında PWA durumunu kontrol et
    window.addEventListener('load', updatePWAStatus);
    window.addEventListener('focus', updatePWAStatus);

    return () => {
      // Event listener'ları temizle
      window.removeEventListener('load', updatePWAStatus);
      window.removeEventListener('focus', updatePWAStatus);
    };
  }, []);

  return (
    <>
   <AppProvider>
      <Router>
        <Header />
        <div>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/A330_Floating" element={<Cal330 />} />
            <Route path="/harm" element={<Harm />} />
            <Route path="/lmd" element={<KilitMaindeck />} />
            <Route path="/afsr" element={<AllFormsa />} />
            <Route path="/loading330" element={<Loading330 />} />
            <Route path="/tt" element={<LenghtCal />} />
            <Route path="/un" element={<UnChecker />} />
            <Route path="/cao" element={<CaoAccess />} />
            
         
          </Routes>
        </div>
        <Footer />
      </Router>
      </AppProvider>
    </>
  );
};

export default App;