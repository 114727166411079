import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AppContext } from './AppContext'; // AppContext'i import et
import '../assets/css/style.css'; // CSS dosyası, ek stil özellikleri için

function Header() {
  const location = useLocation(); // Sayfa konumunu almak için kullanıyoruz
  const navigate = useNavigate(); // Yönlendirme için kullanılıyor

  // AppContext'ten backPath, subtitle, setSubtitle, setBackPath ve onBackClick değerlerini çekiyoruz
  const { backPath, subtitle, setSubtitle, setBackPath, onBackClick, hasSelection } = useContext(AppContext);


  // Sayfa yoluna göre başlık değiştirme fonksiyonu
  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Home';
      case '/loading330':
        return (
          <span>
            H-ARM Calculator{' '}
            <Typography component="span" variant="subtitle2" sx={{ fontSize: '12px', ml: 1 }}>
              {subtitle || 'A330F'}
            </Typography>
          </span>
        );
      case '/lmd':
        return (
          <span>
            Latch Malfunctions{' '}
            <Typography component="span" variant="subtitle2" sx={{ fontSize: '12px', ml: 1 }}>
              {subtitle || 'A330F MD'}
            </Typography>
          </span>
        );
        case '/un':
          return (
            <span>
              UN Scanner{' '}
              <Typography component="span" variant="subtitle2" sx={{ fontSize: '12px', ml: 1 }}>
                {subtitle || 'DGR'}
              </Typography>
            </span>
          );
          case '/cao':
          return (
            <span>
              CAO Accessibility{' '}
              <Typography component="span" variant="subtitle2" sx={{ fontSize: '12px', ml: 1 }}>
                {subtitle || 'DGR'}
              </Typography>
            </span>
          );
        case '/A330_Floating':
          return (
            <span>
              Floating Pallet{' '}
              <Typography component="span" variant="subtitle2" sx={{ fontSize: '12px', ml: 1 }}>
                {subtitle || 'A330F MD'}
              </Typography>
            </span>
          );
      default:
        return 'My Application';
    }
  };

  // Geri Butonunu Gösterme Koşulu
  const showBackButton = location.pathname !== '/' && location.pathname !== '/home';

  // Geri Butonu Tıklama Fonksiyonu
  const handleBackClick = () => {
    if (hasSelection && onBackClick) {
      // Eğer `hasSelection` true ve `onBackClick` tanımlıysa, `KilitMd`'deki handleBackClick'i çağır
      onBackClick();
    } else {
      // Eğer `hasSelection` false ise veya başka bir sayfadaysak, tarayıcı geçmişinde geri git
      navigate(backPath || -1); // backPath varsa oraya git, yoksa önceki sayfaya geri git
    }
  };

  // Koyu Tema Ayarları
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1e88e5',
      },
      secondary: {
        main: '#ff5722',
      },
      background: {
        default: '#121212',
        paper: '#1c1c1c',
      },
      text: {
        primary: '#ffffff',
        secondary: '#bbbbbb',
      },
    },
    typography: {
      fontFamily: '"Roboto", sans-serif',
      fontWeightRegular: 500,
      h5: {
        fontWeight: 700,
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(135deg, #1c1c1c, #2a2a2a, #333333)',
          boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.4)',
          height: '64px',
          display: 'flex',
          justifyContent: 'center',
          borderBottom: `1px solid ${darkTheme.palette.primary.main}`,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
          {/* Geri Butonu veya Boş Alan */}
          {showBackButton ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleBackClick} // Geri butonuna handleBackClick fonksiyonu eklendi
              sx={{
                mr: 2,
                '&:hover': { backgroundColor: darkTheme.palette.action.hover },
              }}
            >
              <ArrowBackIcon sx={{ fontSize: '28px' }} />
            </IconButton>
          ) : (
            <Box sx={{ width: '28px' }} />
          )}

          {/* Dinamik Başlık */}
          <Typography
            variant="h5"
            sx={{
              flexGrow: 1,
              fontWeight: darkTheme.typography.h5.fontWeight,
              color: darkTheme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            {getTitle()}
          </Typography>

          {/* Sağ Boş Alan - Simetri İçin Boş Box */}
          <Box sx={{ width: '28px' }}></Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ height: '16px', backgroundColor: 'transparent' }} />
    </ThemeProvider>
  );
}

export default Header;
